<template>
  <div class="">
    <ControlNavbar/>
    <div class="container" v-if="carfaxObject">
      <div class="banner" v-if="carfaxObject.banner">
        <Banner :banner="carfaxObject.banner"/>
      </div>
      <a-form
          :form="form"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 12 }"
          @submit="handleSubmit"
      >
        <a-form-item :label="carfaxObject.data.plateHint">
          <a-input
              v-decorator="[
              'vin',
              {
                rules: [
                  {
                    required: true,
                    message: `${carfaxObject.data.plateHint} !`,
                  },
                ],
              },
            ]"
          />
        </a-form-item>

        <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
          <a-button type="danger" html-type="submit">
            {{ carfaxObject.data.buttonInfo }}
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
        v-if="carfaxModalResponse"
        v-model="visible"
        :title="`${locale.infofor} ${carfaxModalResponse.data.vin}`"
        @ok="handleOk"
        :footer="null"
    >
      <div class="info-header">
        <p
            v-for="(info, index) in carfaxModalResponse.data.description.split(
            '\n'
          )"
            :key="index"
        >
          {{ info }}
        </p>
      </div>
      <br/>
      <div class="row d-flex justify-content-between">
        <div class="">
          <p class="underline">
            {{ carfaxObject.data.price }} {{ carfaxModalResponse.data.price }}
          </p>
        </div>
        <div class="" v-if="carfaxObject.data.toggleOptional">
          {{ carfaxObject.data.toggle }}
          <a-switch default-checked @change="sendSms"/>
        </div>
      </div>
      <div class="row" v-if="sendSmsCheck">
        <a-input placeholder="Phone number (Optional)" v-model="contact"/>
      </div>
      <br/>
      <a-form-item :wrapper-col="{ span: 12, offset: 8 }">
        <a-button type="danger" @click="getCarfaxReport">
          {{ carfaxObject.data.buttonCarfax }}
        </a-button>
      </a-form-item>
    </a-modal>
    <a-modal
        v-model="paymentOptionsVisible"
        v-if="carfaxModalResponse"
        title="locale.paymentoptions"
        @ok="paymentOptionsVisible = !paymentOptionsVisible"
        :footer="null"
    >
      <div v-if="showPaymentOptions">
        <div
            class="
            d-flex
            justify-content-between
            card_custom
            pointer
            align-items-center
          "
            v-for="option in $session.get('paymentOptions')"
            :key="option.id"
            @click="selectPaymentOption(option)"
        >
          <p>{{ option.label }}</p>
          <p>
            {{ carfaxModalResponse.data.prices[`${option.id}`] }}
          </p>
        </div>
      </div>

      <PaymentCC
          v-if="paymentOption == 'creditcard'"
          @options="
          ($event) => {
            this.showPaymentOptions = $event;
          }
        "
          :apiBody="apiBody"
          @handleOldCarfax="handleOldCarfax"
      />
    </a-modal>
    <loading :loading="loader"/>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
// @ is an alias to /src
import ControlNavbar from "@/components/ControlNavbar";
import Banner from "@/components/Banner";
import PaymentCC from "@/components/PaymentCC";
import Loading from "./Loading.vue";
import {LocaleArabic, LocaleEnglish} from "../scripts/Locale";

export default {
  name: "Carfax",
  components: {ControlNavbar, Banner, PaymentCC, Loading},
  data() {
    return {
      locale: null,
      loader: false,
      contact: null,
      carfaxObject: null,
      formLayout: "horizontal",
      form: this.$form.createForm(this, {name: "coordinated"}),
      visible: false,
      carfaxModalResponse: null,
      sendSmsCheck: null,
      // pay by cc
      paymentOptionsVisible: false,
      paymentOption: null,
      apiBody: null,
      showPaymentOptions: true,
    };
  },
  methods: {
    checkLocal() {
      if (this.$session.get("lang") && this.$session.get("lang") == "ar") {
        this.locale = LocaleArabic;
      } else {
        this.locale = LocaleEnglish;
      }
    },

    setLoader(show) {
      this.loader = show;
    },
    success(result) {
      this.toggleModal();
      let self = this;
      this.$success({
        title: self.locale.reportReady,
        // JSX support
        content: () => (
            <div>
              <a href={result.data.data.url} style="color: #e62946;">
                {self.locale.linkToCarfax}
              </a>
            </div>
        ),
      });
    },
    getAxios() {
      return axios.create({
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          language: this.$session.get("lang") || "en",
          itelVersion: "1000",
          uid: "uidtest1.3",
          brand: "Web",
          model: "Web",
          osVersion: "unix",
          operatingSystem: "4",
          sessionId: `${this.$session.get("sessionId")}`,
          token: `${this.$session.get("token")}`,
          deviceId: `${this.$session.get("deviceid")}`,
          sessionCounter: `${this.$session.get("sessionCounter") || 0}`,
          counter: `${this.$session.get("counter")}`,
        },
      });
    },
    sendSms(checked) {
      this.sendSmsCheck = checked;
      this.contact = null;
    },
    toggleModal() {
      this.visible = !this.visible;
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
    },
    isEmpty(obj) {
      for (var o in obj) {
        if (o) {
          return false;
        }
      }
      return true;
    },
    checkPaymentMethod() {
      // the whole cc payment is here just check payment solution and the modal will do the rest

      // always check prices in carfaxObject object and server.paymentOptions
      // if both are not null
      // if topleft menu (default_payment exists) && usertogglevalue is true do api aade
      // else

      // get intersect between prices and server.paymentoptions (paymentoptions.id == carfaxObject.prices['id']) id is the key of the json
      // if empty do normal api
      //WHISHPAYMENT AND ANY OTHER else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected
      // CREDIT CARD else show payment options (type, show: label) prices: show price marching with paymentoptions when chosen send optionId with the body as the id of the paymentoptions selected

      // if both or one of them null continue aade
      this.paymentOption = null;
      if (
          this.$session.get("paymentOptions") &&
          !this.isEmpty(this.carfaxModalResponse.data.prices)
      ) {
        if (
            this.$session.get("userToggleValue") == true &&
            this.$session.get("default_payment") == true
        ) {
          console.log("do normal api");
          // do normal api
          return true;
        } else {
          console.log("methods");
          // check payments option
          this.paymentOptionsVisible = true;
          this.showPaymentOptions = true;
          return false;
        }
      } else {
        console.log("normal api");
        // do normal api
        return true;
      }
    },
    selectPaymentOption(payOption) {
      // when clicked on the price fields of payment options if credit card open credit card commponent and give it the values that it needs to send to the api
      if (payOption.type == "creditcard") {
        this.form.validateFields((err, values) => {
          if (!err) {
            values.type = this.carfaxObject.type;
            values.fetchPrice = true;
            values.denominationId = this.carfaxObject.id;
            // values.numberOfItems = values.Quantity;
            // delete values.Quantity;
            // this.checkPaymentMethod();
            values.optionId = payOption.id;
            this.apiBody = values;
            this.apiBody.type = this.carfaxObject.type;
            this.paymentOption = payOption.type;
            let contact = this.contact;
            if (!this.carfaxModalResponse.data.toggleOptional) {
              contact = null;
            }
            this.apiBody.id = this.carfaxObject.data.id;
            this.apiBody.vin = this.carfaxModalResponse.data.vin;
            this.apiBody.contact = contact;
            this.apiBody.price = this.carfaxModalResponse.data.priceValue;
            console.log("Received values of form: ", values);
          }
        });
      } else {
        // pay by whish
        this.paymentOptionsVisible = false;
        this.getCarfaxReportByWhish(payOption.id);
      }
    },
    getCarfaxReport() {
      let contact = this.contact;
      if (!this.carfaxModalResponse.data.toggleOptional) {
        contact = null;
      }
      console.log("values", {
        id: this.carfaxObject.data.id,
        vin: this.carfaxModalResponse.data.vin,
        contact: contact,
        price: this.carfaxModalResponse.data.priceValue,
      });
      let values = {
        id: this.carfaxObject.data.id,
        vin: this.carfaxModalResponse.data.vin,
        contact: contact,
        price: this.carfaxModalResponse.data.priceValue,
      };
      if (this.checkPaymentMethod()) {
        this.setLoader(true);
        this.getAxios()
            .post(`${this.$session.get("baseURI")}sale/carfax/get`, values)
            .then((result) => {
              this.setLoader(false);
              if (this.checkApiRes(result, values, "sale/carfax/get", "post")) {
                // ok
                console.log("carfax get", result);
                this.toggleModal();
                this.success(result);
              }
            });
      }
    },

    getCarfaxReportByWhish(optionId) {
      let contact = this.contact;
      if (!this.carfaxModalResponse.data.toggleOptional) {
        contact = null;
      }
      console.log("values", {
        id: this.carfaxObject.data.id,
        vin: this.carfaxModalResponse.data.vin,
        contact: contact,
        price: this.carfaxModalResponse.data.priceValue,
      });
      let values = {
        id: this.carfaxObject.data.id,
        vin: this.carfaxModalResponse.data.vin,
        contact: contact,
        price: this.carfaxModalResponse.data.priceValue,
        optionId: optionId,
      };
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/carfax/get`, values)
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, values, "sale/carfax/get", "post")) {
              // ok
              console.log("carfax get", result);
              this.toggleModal();
              this.success(result);
            }
          });
    },

    handleOldCarfax(values) {
      this.paymentOptionsVisible = false;
      this.setLoader(true);
      this.getAxios()
          .post(`${this.$session.get("baseURI")}sale/carfax/get`, values)
          .then((result) => {
            this.setLoader(false);
            if (this.checkApiRes(result, values, "sale/carfax/get", "post")) {
              // ok
              console.log("carfax get", result);
              this.toggleModal();
              this.success(result);
            }
          });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          values.id = this.carfaxObject.data.id;
          console.log("first carfax values", values);
          this.setLoader(true);
          this.getAxios()
              .post(`${this.$session.get("baseURI")}sale/carfax/info`, values)
              .then((result) => {
                this.setLoader(false);
                if (this.checkApiRes(result, values, "sale/carfax/info", "post")) {
                  // ok
                  console.log("carfax info", result);
                  this.carfaxModalResponse = result.data;
                  this.toggleModal();
                }
              });
        }
      });
    },
  },
  created() {
    this.checkLocal();
    if (this.$route.params.old) {
      let values = this.$route.params.old;
      this.handleOldCarfax(values);
    }
    if (!this.$route.params.carfax) {
      //undefined
      this.carfaxObject = this.$session.get("backup");
      this.sendSmsCheck = this.carfaxObject.data.toggleOptional;
    } else {
      // ok
      this.$session.set("backup", this.$route.params.carfax);
      this.carfaxObject = this.$route.params.carfax;
      this.sendSmsCheck = this.$route.params.carfax.data.toggleOptional;
    }
    console.log("carfax: ", this.carfaxObject);
  },
};
</script>

<style lang="scss" scoped>
.info-header {
  background: gray;
  border-radius: 30px;
  padding: 15px;

  p {
    color: white;
    margin: 0px;
  }
}

.underline {
  text-decoration: underline;
}

.banner {
  width: 100%;
  position: absolute;
  z-index: 999;
  padding: 20px;
  background: #fffcdf;
  left: 0;
}

.banner-protection {
  position: absolute;
  width: 100%;
  height: 100%;
  background: lightgray;
  left: 0;
  z-index: 998;
  opacity: 0.5;
}

.card_custom {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  padding: 2%;
  height: 70px;
  margin-bottom: 10px;
}

.card_custom:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.pointer {
  cursor: pointer;
}
</style>
